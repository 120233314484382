import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavBar from "./Components/Header/NavBar";
import Login from "./Pages/LoginSignUp/Login";
import Otp from "./Pages/LoginSignUp/Otp";
import ProvideDetails from "./Pages/LoginSignUp/ProvideDetails";
import Home from "./Pages/Home/Home";
import Footer from "./Components/Footer/Footer";
import HomeAfterLogin from "./Pages/Home/HomeAfterLogin";
import AboutUs from "./Pages/Home/AboutUs";
import ContactUs from "./Pages/Home/ContactUs";
import Confirm from "./Pages/Home/Confirm";
import UpdateProfile from "./Pages/Home/UpdateProfile";
import ConsolidDetail from "./Pages/Home/ConsolidDetail";
import TopUpDetail from "./Pages/Home/TopUpDetail";
import LowerEmiDetail from "./Pages/Home/LowerEmiDetail";
import Form from "./Pages/Home/Form";
import Status from "./Pages/Home/Status";
import Loan from "./Pages/Home/Loan";
import LowerEmiDetailPage from "./Pages/Home/LowerEmiDetailPage";
import Profession from "./Pages/Home/Profession";
import LoanEdit from "./Pages/Home/LoanEdit";
import Goal from "./Pages/Home/Goal";
import Test from "./Pages/Home/Test";
import Form2 from "./Pages/Home/Form2";
import Statuss from "./Pages/Home/Statuss";
import PrivacyPolicy from "./Pages/Home/PrivacyPolicy";
import TermsandCondition from "./Pages/Home/TermsandCondition";
import ExperianConsent from "./Pages/Home/ExperianConsent";
import { isMobile } from "mobile-device-detect";
import GrievanceAddressal from "./Pages/Home/GrievanceAddressal";
import BalanceTransfer from "./Pages/Home/BalanceTransfer";
import Consolidate from "./Pages/Home/Consolidate";
import Topup from "./Pages/Home/Topup";
import Blog from "./Pages/Home/Blog";
import BlogDetail from "./Pages/Home/BlogDetail";

const App = () => {
  const [isMobile, setIsMobile] = useState(true);
  const token = localStorage.getItem("token");
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token != null) {
      if (!isMobile) {
        //DESKTOP DETECTED LOGOUT USER
        localStorage.removeItem("token");
        window.location.href = "/";
        return;
      } else {
        return () => {};
      }
    }
  }, [isMobile]);

  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);
  return (
    <div>
      <Router scrollRestoration={true}>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/otp" element={<Otp />} />
          <Route path="/goal" element={<Goal />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/confirmloan" element={<LoanEdit />} />
          <Route path="/savingopportunity" element={<LowerEmiDetailPage />} />
          <Route path="/details" element={<LowerEmiDetail />} />
          <Route path="/confirm" element={<Confirm />} />
          <Route path="/dashboard" element={<HomeAfterLogin />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/providedetails" element={<ProvideDetails />} />
          <Route path="/updateprofile" element={<UpdateProfile />} />
          <Route path="/loanoverview" element={<Loan />} />
          <Route path="/test" element={<Test />} />
          <Route path="/topupdetail" element={<TopUpDetail />} />
          <Route path="/personalloanform" element={<Form />} />
          <Route path="/homeloanform" element={<Form2 />} />
          <Route path="/applicationstatus" element={<Statuss />} />
          <Route path="/statusdetail" element={<Status />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/terms&condition" element={<TermsandCondition />} />
          <Route path="/experianconsent" element={<ExperianConsent />} />
          <Route path="/GrievanceAddressal" element={<GrievanceAddressal />} />
          <Route path="/balancetransfer" element={<BalanceTransfer />} />
          <Route path="/loanconsolidation" element={<Consolidate />} />
          <Route path="/topup" element={<Topup />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blogDetail" element={<BlogDetail />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
